import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import { App } from './app';
import reportWebVitals from './reportWebVitals';
import configureStore from './configure-store';
import {
  NAME,
  THEME_PREF_KEY,
  VIEW_PREF_KEY,
  SHOW_HOURS_PREF_KEY,
  HIDE_POSTS_PREF_KEY,
  HIDE_POSTS_BELOW_PREF_KEY,
  DEFAULT_VIEW,
  DEFAULT_THEME,
  DEFAULT_DISPLAY_HOURS,
  DEFAULT_HIDE_POSTS,
  DEFAULT_HIDE_UPVOTE_THRESHOLD,
} from './preferences/constants';

const view = localStorage.getItem(VIEW_PREF_KEY) || DEFAULT_VIEW;
const theme = localStorage.getItem(THEME_PREF_KEY) || DEFAULT_THEME;
const displayHours = localStorage.getItem(SHOW_HOURS_PREF_KEY) || DEFAULT_DISPLAY_HOURS;
const hidePosts = localStorage.getItem(Boolean(HIDE_POSTS_PREF_KEY)) || DEFAULT_HIDE_POSTS;
const hidePostsBelow = localStorage.getItem(Number(HIDE_POSTS_BELOW_PREF_KEY)) || DEFAULT_HIDE_UPVOTE_THRESHOLD;
document.getElementById('body').className = `theme-${theme} layout-${view}`;
const store = configureStore({
  [NAME]: {
    view,
    theme,
    displayHours,
    hidePosts,
    hidePostsBelow,
  }
});
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
