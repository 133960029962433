export const NAME = 'articles';
export const NAMESPACE = NAME;

export const ARTICLE_URL = 'https://api.f5oclock.com/v1/articles';

// numbers used to calculate 'hotness' thresholds
export const RANK_1_THRESHOLD = 750;
export const RANK_2_THRESHOLD = 600;
export const RANK_3_THRESHOLD = 500;
export const RANK_4_THRESHOLD = 350;
export const RANK_5_THRESHOLD = 250;
export const RANK_6_TRESHOLD = 175;
export const RANK_7_THRESHOLD = 100;
export const RANK_8_THRESHOLD = 75;
export const RANK_9_THRESHOLD = 50;
export const RANK_10_THRESHOLD = 10;