import { combineReducers } from 'redux';
import * as types from './action-types';

const showAdvertisements = (state = true, action) => {
  switch (action.type) {
    case 'ROUTER_POP':
    case 'ROUTER_PUSH':
    case types.HIDE_ADS:
      return false;
    // case 'ROUTER_LOCATION_CHANGED':
    case types.SHOW_ADS:
      return true;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  showAdvertisements,
});
