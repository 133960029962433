import React from 'react';
import AdSense from 'react-adsense';
import classNames from 'classnames';
import { Articles } from '../../articles';
import { Header } from '../../header';
import './normalize.css';
import './main.css';

const adsenseClient = 'ca-pub-9451767567731572';

export const App = ({ showAdvertisements, onSummaryPage }) => (
  <main className={classNames('Main', {
    'ArticlesView': !onSummaryPage,
    'SummaryView': onSummaryPage,
  })}>
    <Header />
    <Articles />

    {/* { showAdvertisements && [
      <div key="leaderboard" className="Sponsor leaderboard">
        <div className="Advertisement">
          <AdSense.Google
            client={adsenseClient}
            slot="9519374406"
          />
        </div>
      </div>,
      <div key="cubes" className="Sponsorships">
        <div className="Sponsor cube">
          <div className="Advertisement">
            <AdSense.Google
              client={adsenseClient}
              slot="4575723776"
            />
          </div>
        </div>
        <div className="Sponsor cube">
          <div className="Advertisement">
            <AdSense.Google
              client={adsenseClient}
              slot="1733938017"
            />
          </div>
        </div>
      </div>
    ] } */}
  </main>
);

App.propTypes = {};
App.displayName = 'App';
