import { NAME } from './constants';

export const view = state => state[NAME].view;
export const theme = state => state[NAME].theme;
export const displayHours = state => state[NAME].displayHours;
export const hidePosts = state => state[NAME].hidePosts;
export const hidePostsBelow = state => state[NAME].hidePostsBelow;
export const themeClass = state => `theme-${theme(state)}`;
export const viewClass = state => `layout-${view(state)}`;
export const isExpanded = state => state[NAME].isExpanded;
