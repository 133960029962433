import { ARTICLE_CHANGES } from "./articles/action-types";

const socket = new WebSocket('wss://ws.f5oclock.com/');

export default function setupSocket(store) {
  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    console.log(data);
    if (data.type === 'articles') {
      store.dispatch({
        type: ARTICLE_CHANGES,
        payload: data.payload,
      });
    }
  };
}