export const NAME = 'userPreferences';
export const NAMESPACE = 'user-preferences';

export const DARK_THEME = 'dark';
export const LIGHT_THEME = 'light';
export const TABLE_VIEW = 'table';
export const CARDS_VIEW = 'cards';

export const DEFAULT_THEME = DARK_THEME;
export const DEFAULT_VIEW = CARDS_VIEW;
export const DEFAULT_HIDE_UPVOTE_THRESHOLD = 0;
export const DEFAULT_HIDE_POSTS = true;
export const DEFAULT_DISPLAY_HOURS = 2;

export const DISPLAY_HOUR_OPTIONS = [.5, 1, 2, 6, 12, 24];
export const THEME_PREF_KEY = 'theme';
export const VIEW_PREF_KEY = 'view';
export const SHOW_HOURS_PREF_KEY = 'show_hours';
export const HIDE_POSTS_PREF_KEY = 'hide_posts';
export const HIDE_POSTS_BELOW_PREF_KEY = 'hide_post_threshold';
