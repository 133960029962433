import { connect } from 'react-redux';
import lifecycle from 'react-pure-lifecycle';
import { getArticles } from '../../articles/actions';
import { showAdvertisements } from '../selectors';
import { App } from '../components/App';

const mapStateToProps = state => ({
  showAdvertisements: showAdvertisements(state),
});

const mapDispatchToProps = {
  getArticles,
};

const methods = {
  componentWillMount: props => {
   
  },
};

const container = connect(mapStateToProps, mapDispatchToProps)(
  lifecycle(methods)(App)
);

export { container as App };
