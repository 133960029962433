import { combineReducers } from 'redux';
import * as types from './action-types';
import { HIDE_ADS, SHOW_ADS } from '../app/action-types';
import {
  DEFAULT_THEME,
  DEFAULT_VIEW,
  DEFAULT_DISPLAY_HOURS,
  DEFAULT_HIDE_POSTS,
  DEFAULT_HIDE_UPVOTE_THRESHOLD,
} from './constants';

const view = (state = DEFAULT_VIEW, action) => {
  switch (action.type) {
    case types.SET_VIEW:
      return action.payload;
    default:
      return state;
  }
};

const theme = (state = DEFAULT_THEME, action) => {
  switch (action.type) {
    case types.SET_THEME:
      return action.payload;
    default:
      return state;
  }
};

const displayHours = (state = DEFAULT_DISPLAY_HOURS, action) => {
  switch (action.type) {
    case types.SET_DISPLAY_HOURS:
      return action.payload;
    default:
      return state;
  }
};

const hidePosts = (state = DEFAULT_HIDE_POSTS, action) => {
  switch (action.type) {
    case types.SET_HIDE_POSTS_PREF:
      return action.payload;
    default:
      return state;
  }
};

const hidePostsBelow = (state = DEFAULT_HIDE_UPVOTE_THRESHOLD, action) => {
  switch (action.type) {
    case types.SET_HIDE_POSTS_BELOW:
      return action.payload;
    default:
      return state;
  }
};

const isExpanded = (state = false, action) => {
  switch (action.type) {
    case types.CLOSE_MENU:
      return false;
    case types.TOGGLE_EXPANDED:
      return !state;
    default:
      return state;
  }
};

const showAdvertisements = (state = true, action) => {
  switch (action.type) {
    case HIDE_ADS:
      return false;
    case SHOW_ADS:
      return true;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  view,
  theme,
  displayHours,
  hidePosts,
  hidePostsBelow,
  isExpanded,
  showAdvertisements
});
