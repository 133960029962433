import React from 'react';

export default function InsertBreaks({ text, returnFirst }) {
  if (!text) return <span />;
  const split = text.split(/\[BREAK\]/g);

  if (returnFirst) {
    return <span>{split[0]}</span>;
  }
  
  return <span>{
    split.map(line => <p key={line}>{line}</p>)
  }</span>;
}
