import {
  NAME,
  RANK_3_THRESHOLD,
  RANK_5_THRESHOLD,
  RANK_7_THRESHOLD,
  RANK_1_THRESHOLD,
  RANK_2_THRESHOLD,
  RANK_4_THRESHOLD,
  RANK_6_TRESHOLD,
  RANK_8_THRESHOLD,
  RANK_9_THRESHOLD,
  RANK_10_THRESHOLD,
} from './constants';
import {
  hidePosts,
  displayHours,
  hidePostsBelow,
} from '../preferences/selectors';

export const articles = state => state[NAME].articles;
export const displayArticles = state => articles(state).filter(article => {
  const utcDate = Math.floor(new Date().getTime() / 1000);
  const displayCutoff = utcDate - (displayHours(state) * 3600);
  const withinTimeFrame = article.created_utc >= displayCutoff;
  const aboveThreshold = article.ups > hidePostsBelow(state);
  return withinTimeFrame && (hidePosts(state) ? aboveThreshold : true);
}).sort((a,b) => b.ups - a.ups);

export const articleById = state => id => articles(state).filter(article => article.id === id)[0];
export const loadingArticles = state => state[NAME].loadingArticles;

// rank calculation

export const rank1 = ups => ups >= RANK_1_THRESHOLD;
export const rank2 = ups => ups >= RANK_2_THRESHOLD && ups < RANK_1_THRESHOLD;
export const rank3 = ups => ups >= RANK_3_THRESHOLD && ups < RANK_2_THRESHOLD;
export const rank4 = ups => ups >= RANK_4_THRESHOLD && ups < RANK_3_THRESHOLD;
export const rank5 = ups => ups >= RANK_5_THRESHOLD && ups < RANK_4_THRESHOLD;
export const rank6 = ups => ups >= RANK_6_TRESHOLD && ups < RANK_5_THRESHOLD;
export const rank7 = ups => ups >= RANK_7_THRESHOLD && ups < RANK_6_TRESHOLD;
export const rank8 = ups => ups >= RANK_8_THRESHOLD && ups < RANK_7_THRESHOLD;
export const rank9 = ups => ups >= RANK_9_THRESHOLD && ups < RANK_8_THRESHOLD;
export const rank10 = ups => ups >= RANK_10_THRESHOLD && ups < RANK_9_THRESHOLD;
export const rank0 = ups => ups < RANK_10_THRESHOLD;
