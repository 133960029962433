import { ARTICLE_URL } from './constants';
import * as types from './action-types';

export const getArticlesFailure = payload => ({
  type: types.GET_ARTICLES_FAILURE,
  payload,
});

export const getArticlesSuccess = payload => ({
  type: types.GET_ARTICLES_SUCCESS,
  payload,
});

const fetchArticles = (dispatch) => {
  dispatch({ type: types.GET_ARTICLES });
  fetch(ARTICLE_URL)
    .then(data => data.json())
    .then(data => data.sort((a,b) => b.ups - a.ups))
    .then(data => data.map(d => {
      d.summary = d.summary || '';
      return d;
    }))
    .then(data => dispatch(getArticlesSuccess(data)))
    .catch(error => dispatch(getArticlesFailure(error)));
};

export const getArticles = () => (dispatch) => fetchArticles(dispatch);
