import * as types from './action-types';
import {
  VIEW_PREF_KEY,
  THEME_PREF_KEY,
  SHOW_HOURS_PREF_KEY,
  HIDE_POSTS_PREF_KEY,
  HIDE_POSTS_BELOW_PREF_KEY,
  DARK_THEME,
  LIGHT_THEME,
  TABLE_VIEW,
  CARDS_VIEW,
} from './constants';
import {
  view,
  theme,
  hidePosts,
} from './selectors';

export const toggleViewPreference = () => (dispatch, getState) => {
  const state = getState();
  const currentView = view(state);
  const pref = currentView === CARDS_VIEW ? TABLE_VIEW : CARDS_VIEW;
  localStorage.setItem(VIEW_PREF_KEY, pref);
  dispatch({
    type: types.SET_VIEW,
    payload: pref,
  });
};

export const toggleThemePreference = () => (dispatch, getState) => {
  const state = getState();
  const currentTheme = theme(state);
  const pref = currentTheme === DARK_THEME ? LIGHT_THEME : DARK_THEME;
  localStorage.setItem(THEME_PREF_KEY, pref);
  dispatch({
    type: types.SET_THEME,
    payload: pref,
  });
};

export const setDisplayHours = (e) => (dispatch) => {
  const hours = e.target.value;
  localStorage.setItem(SHOW_HOURS_PREF_KEY, hours);
  dispatch({
    type: types.SET_DISPLAY_HOURS,
    payload: hours,
  });
};

export const toggleHidePostsPreference = () => (dispatch, getState) => {
  const state = getState();
  const newPreference = !hidePosts(state);
  localStorage.setItem(HIDE_POSTS_PREF_KEY, newPreference);
  dispatch({
    type: types.SET_HIDE_POSTS_PREF,
    payload: newPreference,
  });
};

export const setHidePostsBelow = (e) => (dispatch) => {
  const threshold = e.target.value;
  localStorage.setItem(HIDE_POSTS_BELOW_PREF_KEY, threshold);
  dispatch({
    type: types.SET_HIDE_POSTS_BELOW,
    payload: threshold,
  });
};

export const toggleExpanded = () => ({
  type: types.TOGGLE_EXPANDED,
});

export const closeMenu = () => ({
  type: types.CLOSE_MENU,
});
