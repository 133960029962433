import React from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Preferences } from '../../preferences';
import './Header.css';

export const Header = ({
  refreshing,
  getArticles
}) => (
  <header className={classNames('Header', {
    'is-Refreshing': refreshing,
  })}>
    <a href='#' onClick={getArticles}>
      <h1 className='Header--Logo Logo'>
        <svg className="Logo--icon" viewBox="0 0 56 44" xmlns="http://www.w3.org/2000/svg">
          <path className="Logo--icon--arrow" d="M24.416 38.912l-1 4.997c-6.124.57-12.443-1.526-17.127-6.289-8.342-8.482-8.413-22.269-.048-30.751 8.366-8.482 21.784-8.482 30.15 0L43 0v17H26.738l6.53-6.489c-6.506-6.6-17.04-6.844-23.547-.244-6.506 6.624-6.506 17.307 0 23.955 4 4.058 9.522 5.62 14.695 4.69z" />
          <path className="Logo--icon--cruft" d="M20.256 12.222h3.575v12.223L13.63 30.654l-1.716-2.958 8.342-5.085zM54.683 25.967H48.46l-.844 3.937c.291-.1.596-.179.916-.238.32-.06.597-.09.83-.09.794 0 1.497.144 2.108.433a4.26 4.26 0 0 1 1.526 1.193c.407.507.718 1.098.93 1.774.214.676.32 1.392.32 2.148 0 1.332-.198 2.52-.595 3.564-.398 1.044-.936 1.924-1.614 2.64a6.711 6.711 0 0 1-2.385 1.625 7.694 7.694 0 0 1-2.908.552c-1.59 0-2.913-.432-3.97-1.297-1.056-.865-1.71-2.073-1.963-3.624l3.955-1.342c.136.636.383 1.173.742 1.61.359.438.858.656 1.498.656.465 0 .872-.099 1.221-.298.349-.199.64-.472.872-.82.233-.348.408-.746.524-1.193.116-.448.174-.93.174-1.447 0-.914-.247-1.605-.741-2.073-.495-.467-1.13-.7-1.905-.7-.29 0-.616.024-.974.074a16.122 16.122 0 0 0-2.24.477c-.378.11-.722.224-1.032.343l2.443-11.87H55.41l-.727 3.966zM41.277 26.146h-5.41l-.756 4.504h5.031l-.668 3.967h-5.002l-1.454 8.5h-4.42L32.173 22h9.8z" />
          <path className="Logo--icon--refreshing" d="M30.1677116,34.9538039 L29.4236275,38.6719926 C24.8668559,39.0961206 20.1649879,37.5365201 16.6796976,33.9924471 C10.4725473,27.681125 10.4197173,17.4224364 16.6439815,11.1111143 C22.8689899,4.79979221 32.8531115,4.79979221 39.0781198,11.1111143 L43.9957722,6 L43.9957722,18.6494312 L31.8954751,18.6494312 L36.7543449,13.8210689 C31.9133331,8.91011326 24.0751503,8.72855671 19.2333945,13.6395123 C14.3923828,18.568326 14.3923828,26.5173774 19.2333945,31.464049 C22.2097313,34.4835427 26.3185641,35.6458022 30.1677116,34.9538039 Z" />
        </svg>
        <span className="Logo--text visuallyhidden">F5 o'clock</span>
      </h1>
    </a>
    <Preferences />
  </header>
);

Header.propTypes = {
  refreshing: PropTypes.bool.isRequired,
  getArticles: PropTypes.func.isRequired,
};
