import { combineReducers } from 'redux';
import * as types from './action-types';

const articles = (state = [], action) => {
  switch (action.type) {
    case types.ARTICLE_CHANGES: {
      const newArticles = action.payload.filter(article => !state.find(a => a.id === article.id));
      if (!state.length) return newArticles;
      const newState = state.reduce((acc, article) => {
        // if article exists in payload array use payload article
        const payloadArticle = action.payload.find(pa => pa.id === article.id);
        if (payloadArticle) {
          return [...acc, payloadArticle];
        }
        return [...acc, article];
      }, []);

      return [...newState, ...newArticles];
    }
    case types.GET_ARTICLES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const loadingArticles = (state = false, action) => {
  switch (action.type) {
    case types.GET_ARTICLES:
      return true;
    case types.GET_ARTICLES_SUCCESS:
    case types.GET_ARTICLES_FAILURE:
      return false;
    default:
      return state;
  }
};

const articleError = (state = {}, action) => {
  switch (action.type) {
    case types.GET_ARTICLES:
      return {};
    case types.GET_ARTICLES_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  articles,
  loadingArticles,
  articleError,
});
