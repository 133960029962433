import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { TABLE_VIEW, CARDS_VIEW, LIGHT_THEME, DARK_THEME, DISPLAY_HOUR_OPTIONS } from '../constants';
import './Preferences.css';

export const Preferences = ({
  view,
  theme,
  displayHours,
  hidePosts,
  hidePostsBelow,
  isExpanded,
  toggleViewPreference,
  toggleThemePreference,
  toggleHidePostsPreference,
  toggleExpanded,
  setHidePostsBelow,
  setDisplayHours,
  closeMenu,
}) => ( 
  <div className="Preferences">
    <OutsideClickHandler onOutsideClick={closeMenu}>
      <h3 className={classNames('Preferences--Menu-Button', {
        'is-Expanded': isExpanded,
      })} onClick={toggleExpanded}>
        <svg className='Preferences--Menu-Button--icon' viewBox="0 0 24 24">
          <path d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />
        </svg>
        <span className="Preferences--Menu-Button--text visuallyhidden focusable">Preferences</span>
      </h3>
      <ul className="Preferences--List">
        <li className="Preferences--List--item layout">
          <h4 className="Preferences--Label">Layout:</h4>
          <ul className="Preference">
            <li className="Preference--item" onClick={toggleViewPreference}>
              <input
                id="layout-cards"
                className="Option--radio"
                type="radio"
                name="layout"
                readOnly
                checked={view === CARDS_VIEW }
              />
              <label className="Option--label">
                <svg className="Option--label--icon icon" viewBox="0 0 24 24">
                  <path d="M16,5V11H21V5M10,11H15V5H10M16,18H21V12H16M10,18H15V12H10M4,18H9V12H4M4,11H9V5H4V11Z" />
                </svg>
                <span className="Option--label--text visuallyhidden focusable">Card Layout</span>
              </label>
            </li>
            <li className="Preference--item" onClick={toggleViewPreference}>
              <input
                id="layout-table"
                className="Option--radio"
                type="radio"
                name="layout"
                readOnly
                checked={view === TABLE_VIEW}
              />
              <label className="Option--label">
                <svg className="Option--label--icon icon" viewBox="0 0 24 24">
                  <path d="M4,5V7H21V5M4,11H21V9H4M4,19H21V17H4M4,15H21V13H4V15Z" />
                </svg>
                <span className="Option--label--text visuallyhidden focusable">Table Layout</span>
              </label>
            </li>
          </ul>
        </li>
        <li className="Preferences--List--item theme">
          <h4 className="Preferences--Label">Theme:</h4>
          <ul className="Preference">
            <li className="Preference--item" onClick={toggleThemePreference}>
              <input
                id="theme-light"
                value="light"
                className="Option--radio"
                type="radio"
                name="theme"
                readOnly
                checked={theme === LIGHT_THEME}
              />
              <label className="Option--label">
                <svg className="Option--label--icon icon" viewBox="0 0 24 24">
                  <path d="M3.55,18.54L4.96,19.95L6.76,18.16L5.34,16.74M11,22.45C11.32,22.45 13,22.45 13,22.45V19.5H11M12,5.5A6,6 0 0,0 6,11.5A6,6 0 0,0 12,17.5A6,6 0 0,0 18,11.5C18,8.18 15.31,5.5 12,5.5M20,12.5H23V10.5H20M17.24,18.16L19.04,19.95L20.45,18.54L18.66,16.74M20.45,4.46L19.04,3.05L17.24,4.84L18.66,6.26M13,0.55H11V3.5H13M4,10.5H1V12.5H4M6.76,4.84L4.96,3.05L3.55,4.46L5.34,6.26L6.76,4.84Z" />
                </svg>
                <span className="Option--label--text visuallyhidden focusable">Light Theme</span>
              </label>
            </li>
            <li className="Preference--item" onClick={toggleThemePreference}>
              <input
                id="theme-dark"
                value="dark"
                className="Option--radio"
                type="radio"
                name="theme"
                readOnly
                checked={theme === DARK_THEME}
              />
              <label className="Option--label">
                <svg className="Option--label--icon icon" viewBox="0 0 24 24">
                  <path d="M17.742 4.09l-2.53 1.94.91 3.06-2.63-1.81-2.63 1.81.91-3.06-2.53-1.94 3.19-.09 1.06-3 1.06 3 3.19.09zm3.5 6.91l-1.64 1.25.59 1.98-1.7-1.17-1.7 1.17.59-1.98-1.64-1.25 2.06-.05.69-1.95.69 1.95 2.06.05zm-2.28 4.95c.83-.08 1.72 1.1 1.19 1.85-.32.45-.66.87-1.08 1.27-3.91 3.93-10.24 3.93-14.14 0-3.91-3.9-3.91-10.24 0-14.14.4-.4.82-.76 1.27-1.08.75-.53 1.93.36 1.85 1.19-.27 2.86.69 5.83 2.89 8.02a9.96 9.96 0 0 0 8.02 2.89z" />
                </svg>
                <span className="Option--label--text visuallyhidden focusable">Dark Theme</span>
              </label>
            </li>
          </ul>
        </li>
        <li className="Preferences--List--item upvotes fw">
          <div className="MultiPreference">
            <input
              id="show-low-upvotes"
              className="Option--checkbox"
              type="checkbox"
              name="show-low-upvotes"
              readOnly
              checked={hidePosts}
            />
            <label
              className="MultiPreference--Label checkbox"
              onClick={toggleHidePostsPreference}
            >
              Hide Low Upvotes
            </label>
          </div>
          { hidePosts &&
            <div className="MultiPreference">
              <label
                htmlFor="hide-posts-threshold"
                className="MultiPreference--caption"
              >
                Threshold:
              </label>
              <input
                id="hide-posts-threshold"
                className="Option--number"
                type="number"
                min="0"
                step="5"
                name="hide-posts-threshold"
                onChange={setHidePostsBelow}
                value={hidePostsBelow}
              />
            </div>
          }
        </li>
        <li className="Preferences--List--item time-to-view">
          <h4 className="Preferences--Label">Show Last:</h4>
          <ul className="Preference">
            <li className="Preference--item">
              <select
                id="time-range"
                className="Option--number"
                type="number"
                name="hide-posts-threshold"
                onChange={setDisplayHours}
                value={displayHours}
              >
                { DISPLAY_HOUR_OPTIONS.map(opt =>
                  <option key={opt} value={opt}>
                    { opt === 0.5 && '30 min' }
                    { opt === 1 && '1 hour' }
                    { opt > 1 && `${opt} hours` }
                  </option>
                )}
              </select>
            </li>
          </ul>
        </li>
      </ul>
    </OutsideClickHandler>
  </div>
);

Preferences.propTypes = {
  view: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  displayHours: PropTypes.string.isRequired,
  hidePosts: PropTypes.bool.isRequired,
  hidePostsBelow: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  toggleViewPreference: PropTypes.func.isRequired,
  toggleThemePreference: PropTypes.func.isRequired,
  toggleHidePostsPreference: PropTypes.func.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  setHidePostsBelow: PropTypes.func.isRequired,
  setDisplayHours: PropTypes.func.isRequired
};
