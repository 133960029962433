import { connect } from 'react-redux';
import { loadingArticles } from '../../articles/selectors';
import { getArticles } from '../../articles/actions';
import { Header } from '../components/Header.jsx';

const mapStateToProps = state => ({
  refreshing: loadingArticles(state),
});

const mapDispatchToProps = {
  getArticles
};

const container = connect(mapStateToProps, mapDispatchToProps)(Header);

export { container as Header };
