import { HIDE_ADS, SHOW_ADS } from './action-types';

export const showAdsDelayed = () => (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SHOW_ADS });
  }, 500);
};

export const toggleAds = () => (dispatch) => {
  dispatch({ type: HIDE_ADS });
  showAdsDelayed()(dispatch);
};