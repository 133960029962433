import { connect } from 'react-redux';
import lifecycle from 'react-pure-lifecycle';
import {
  view,
  theme,
  displayHours,
  hidePosts,
  hidePostsBelow,
  isExpanded,
  themeClass,
  viewClass,
} from '../selectors';
import {
  toggleExpanded,
  toggleViewPreference,
  toggleThemePreference,
  toggleHidePostsPreference,
  setHidePostsBelow,
  setDisplayHours,
  closeMenu
} from '../actions';
import { Preferences } from '../components/Preferences.jsx';

const mapStateToProps = state => ({
  view: view(state),
  bodyClass: `${viewClass(state)} ${themeClass(state)}`,
  theme: theme(state),
  displayHours: displayHours(state),
  hidePosts: hidePosts(state),
  hidePostsBelow: hidePostsBelow(state),
  isExpanded: isExpanded(state),
});

const mapDispatchToProps = {
  toggleExpanded,
  toggleViewPreference,
  toggleThemePreference,
  toggleHidePostsPreference,
  setHidePostsBelow,
  setDisplayHours,
  closeMenu,
};

const methods = {
  componentWillReceiveProps: (oldProps, newProps) => {
    if (oldProps.bodyClass !== newProps.bodyClass) {
      const element = document.getElementById('body');
      element.className = newProps.bodyClass;
    }
  },
};

const container = connect(mapStateToProps, mapDispatchToProps)(
  lifecycle(methods)(Preferences)
);

export { container as Preferences };
