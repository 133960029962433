import React from 'react';
import { PropTypes } from 'prop-types';
import { Article } from '../containers/Article';
import './home.css';

export const Articles = ({
  articles,
}) => (
  <section className="Articles Content">
    <header className="Articles--thead">
      <ul className="Articles--thead--tr">
        <li className="Articles--thead--th upvotes">
          <svg className="Articles--thead--icon" viewBox="0 0 24 24">
            <path d="M15,20H9V12H4.16L12,4.16L19.84,12H15V20Z" />
          </svg>
        </li>
        <li className="Articles--thead--th comments">
          <svg className="Articles--thead--icon" viewBox="0 0 24 24">
            <path d="M3,15H1V3A2,2 0 0,1 3,1H19V3H3V15M12,23A1,1 0 0,1 11,22V19H7A2,2 0 0,1 5,17V7A2,2 0 0,1 7,5H21A2,2 0 0,1 23,7V17A2,2 0 0,1 21,19H16.9L13.2,22.71C13,22.89 12.76,23 12.5,23H12M9,9V11H19V9H9M9,13V15H17V13H9Z" />
          </svg>
        </li>
        <li className="Articles--thead--th title">
          Title
        </li>
        <li className="Articles--thead--th source">
          Source
        </li>
        <li className="Articles--thead--th posted">
          Posted
        </li>
      </ul>
    </header>
    { articles.map(article => <Article key={article.id} article={article} />) }
  </section>
);

Article.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object),
};
Article.defaultProps = {
  articles: [],
};
