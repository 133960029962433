import { connect } from 'react-redux';
import lifecycle from 'react-pure-lifecycle';
import { displayArticles } from '../selectors';
import { getArticles } from '../actions';
import { showAdsDelayed } from '../../app/actions';
import { Articles } from '../components/Articles.jsx';


const mapStateToProps = state => ({
  articles: displayArticles(state),
});

const mapDispatchToprops = {
  getArticles,
  showAdsDelayed,
};

const methods = {
  componentWillMount: props => {
    props.showAdsDelayed();
    if (!props.articles.length) {
      props.getArticles();
    }
  },
  componentWillReceiveProps: (oldProps, newProps) => {
    newProps.showAdsDelayed();
  }
};

const container = connect(mapStateToProps, mapDispatchToprops)(
  lifecycle(methods)(Articles)
);

export { container as Articles };
