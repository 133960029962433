import { NAMESPACE } from './constants';
import actionTypeConstructor from '../util/action-type-constructor';

const ca = actionTypeConstructor(NAMESPACE);

export const SET_VIEW = ca('SET_VIEW_PREFERENCE');
export const SET_THEME = ca('SET_THEME_PREFERENCE');
export const SET_DISPLAY_HOURS = ca('SET_DISPLAY_HOURS');
export const SET_HIDE_POSTS_PREF = ca('SET_HIDE_POSTS_PREF');
export const SET_HIDE_POSTS_BELOW = ca('SET_HIDE_POSTS_BELOW');
export const TOGGLE_EXPANDED = ca('TOGGLE_EXPANDED');
export const CLOSE_MENU = ca('CLOSE_MENU');
