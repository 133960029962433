import {
  createStore,
  applyMiddleware,
  compose
} from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import reducer from './reducers';
import setupSocket from './socket';

export default function configureStore(initialState) {
  const store = createStore(
    reducer,
    initialState,
    compose(applyMiddleware(thunk/*, logger */))
  );
  setupSocket(store);
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers');
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
