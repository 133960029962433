import { connect } from 'react-redux';
import { toggleAds } from '../../app/actions';
import { Article } from '../components/Article.jsx';

const mapDispatchToProps = {
  toggleAds,
};

const container = connect(null, mapDispatchToProps)(Article);

export { container as Article };