import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import InsertBreaks from '../../util/insert-breaks';
import { rank1, rank2, rank3, rank4, rank5, rank6, rank7, rank8, rank9, rank10, rank0 } from '../selectors';

// TODO: make algo for calculating hotness percentage
export const Article = ({
  article,
  toggleAds,
}) => (
  <article className={classNames('Article', {
    'article-hotness-100': rank1(article.ups),
    'article-hotness-90': rank2(article.ups),
    'article-hotness-80': rank3(article.ups),
    'article-hotness-70': rank4(article.ups),
    'article-hotness-60': rank5(article.ups),
    'article-hotness-50': rank6(article.ups),
    'article-hotness-40': rank7(article.ups),
    'article-hotness-30': rank8(article.ups),
    'article-hotness-20': rank9(article.ups),
    'article-hotness-10': rank10(article.ups),
    'article-hotness-0': rank0(article.ups),
  })}> 
    <header className="Article--header hotness-bg">
      <h2 className="Article--headline">
        <a
          onClick={toggleAds}
          href={article.url}
          rel="noopener noreferrer"
          target="_blank">
          { article.title }
        </a>
      </h2>
    </header>
    <ul className="Article--social">
      <li className="Article--social--item Social">
        <svg className="Social--icon" viewBox="0 0 24 24">
          <path d="M15,20H9V12H4.16L12,4.16L19.84,12H15V20Z" />
        </svg>
        <span className="Social--label">Upvotes</span>
        <span className="Social--count">{ article.ups }</span>
      </li>
      <li className="Article--social--item Social">
        <svg className="Social--icon" viewBox="0 0 24 24">
          <path d="M3,15H1V3A2,2 0 0,1 3,1H19V3H3V15M12,23A1,1 0 0,1 11,22V19H7A2,2 0 0,1 5,17V7A2,2 0 0,1 7,5H21A2,2 0 0,1 23,7V17A2,2 0 0,1 21,19H16.9L13.2,22.71C13,22.89 12.76,23 12.5,23H12M9,9V11H19V9H9M9,13V15H17V13H9Z" />
        </svg>
        <a
          href={`https://reddit.com${article.permalink}`}
          className="Social--wrap"
          rel="noopener noreferrer"
          target="_blank">
          <span className="Social--label">Comments</span>
          <span className="Social--count">{ article.num_comments }</span>
        </a>
      </li>
    </ul>
    {
      [
        article.summary && <p key="summary" className="Article--summary"><InsertBreaks text={article.summary} returnFirst /></p>,
        !article.summary && <p key="summarize" className="Article--summary">
          Read on <a
            onClick={toggleAds}
            className="NoDecoration"
            href={article.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {article.domain}
          </a>.
        </p>
      ]
    }

    { article.topComment &&
      <figure className="Article--top-comment Top-Comment">
        <p>{ article.topComment }</p>
        <figcaption>
          Posted By: <cite>{ article.topCommentAuthor }</cite>
        </figcaption>
      </figure>
    }

    <footer className="Article--footer">
      <p className="Article--source">{ article.domain }</p>
      <p className="Article--posted">
        { moment.unix(article.created_utc).fromNow() }
      </p>
    </footer>
  </article>
);

Article.propTypes = {
  article: PropTypes.object.isRequired,
};
